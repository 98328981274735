import { Box } from "@chakra-ui/react";
import { FC } from "react";

const PageScriptTypeform: FC<{ typeformUrl: string }> = ({ typeformUrl }) => (
  <Box position="fixed" top={0} left={0} width="100vw" height="100vh">
    <iframe
      allow="camera; microphone; autoplay; encrypted-media;"
      frameBorder="0"
      height="100%"
      id="typeform-full"
      src={typeformUrl}
      title="typeform-full"
      width="100%"
    />
  </Box>
);

export default PageScriptTypeform;
