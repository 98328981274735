const STORYLANE_URL_PREFIX = 'https://shine.storylane.io/';

const isStorylaneUrl = (str?: string) => {
  if (!str) {
    return false;
  }

  // This ensures the resulting `src` attribute for the `iframe` can be trusted
  // It acts as an allow-list
  const isValid = [
    `${STORYLANE_URL_PREFIX}share/`,
    `${STORYLANE_URL_PREFIX}demo/`
  ].some((prefix) => str.startsWith(prefix));

  return isValid;
};

const sanitizeStorylaneUrl = (str?: string) => {
  if (!str) {
    return null;
  }

  const isValid = isStorylaneUrl(str);
  if (!isValid) {
    return null;
  }

  /**
   * The share version would render an additional iframe compared to demo version.
   * Even though content creators should not,
   * we can expect them to unintentionally use this share version
   * because it is exposed by Storylane within the Share instructions.
   */
  return str.replace(`${STORYLANE_URL_PREFIX}share/`, `${STORYLANE_URL_PREFIX}demo/`);
};

export { isStorylaneUrl, sanitizeStorylaneUrl };
