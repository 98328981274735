import { Box } from "@chakra-ui/react";
import { FC } from "react";
import useIframe from "src/hooks/useIframe";

const PageScriptIframe: FC<{ script: string }> = ({ script }) => {
  const iframe = useIframe(script);

  return (
    <Box
      as="iframe"
      ref={iframe}
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      src="about:blank"
      title="embed"
    />
  );
};

export default PageScriptIframe;
