import { IDidomiObject } from "@didomi/react";
import { graphql, useStaticQuery } from "gatsby";
import { FC, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { getBreadcrumbListMarkup, getImageObjectMarkup, getWebPageMarkup } from "./Markups";
import { Organization, Product, WebSite } from "schema-dts";
import { BreadcrumbListMarkupType, GraphWithContext, ImageObjectMarkupType, WebPageMarkupType } from "./Markups/types";

interface MetaProps {
  lang?: string;
  url?: string;
  title?: string;
  socialMediaTitle?: string;
  socialMediaImage?: string;
  metadescription?: string;
  indexed?: boolean;
  hotjar?: boolean;
  breadcrumbMarkup?: BreadcrumbListMarkupType;
  webPageMarkup?: WebPageMarkupType;
  imageObjectMarkup?: ImageObjectMarkupType | ImageObjectMarkupType[];
}

const DEFAULT_THUMBNAIL = `${process.env.GATSBY_HOST}/images/meta-thumbnail.png`;

const DEFAULT_TITLE = "Le compte pro en ligne qui simplifie votre quotidien";

const DEFAULT_META_DESCRIPTION =
  "Le compte pro en ligne adopté par 100 000 entrepreneurs ✓ RIB français ✓ Mastercard Business ✓ Comptabilité simplifiée et bien plus";

const Meta: FC<MetaProps> = (props) => {
  const { prismicSiteHead } = useStaticQuery<Gatsby.SiteHeadDataQuery>(graphql`
    query SiteHeadData {
      prismicSiteHead {
        ...SiteHead
      }
    }
  `);

  const didomiRef = useRef<null | IDidomiObject>(null);

  const [shouldAddHotjar, setShouldAddHotjar] = useState(false);

  /**
   * We set default values here to make sure `null` values are replaced too.
   */
  const data = {
    url: props.url,
    lang: props.lang ?? "fr",
    indexed: props.indexed ?? true,
    title: props.title ?? DEFAULT_TITLE,
    metadescription: props.metadescription ?? DEFAULT_META_DESCRIPTION,
    socialMediaTitle: props.socialMediaTitle ?? props.title ?? DEFAULT_TITLE,
    socialMediaImage: props.socialMediaImage ?? DEFAULT_THUMBNAIL,
  };

  if (props.hotjar && typeof window !== "undefined") {
    (window as any).didomiOnReady = (window as any).didomiOnReady || [];

    (window as any).didomiOnReady.push((didomi: IDidomiObject) => {
      didomiRef.current = didomi;

      if (didomi.getCurrentUserStatus().vendors.hotjar?.enabled && !shouldAddHotjar) {
        setShouldAddHotjar(true);
      }
    });

    (window as any).didomiEventListeners = (window as any).didomiEventListeners || [];

    (window as any).didomiEventListeners.push({
      event: 'consent.changed',
      listener: () => {
        const didomi = didomiRef.current;

        if (didomi === null) {
          return;
        }

        if (didomi.getCurrentUserStatus().vendors.hotjar?.enabled && !shouldAddHotjar) {
          setShouldAddHotjar(true);
        }
      }
    });
  }

  /**
   * Schema markups
   */
  const schemaMarkup: GraphWithContext = {
    "@context": "https://schema.org",
    "@graph": [],
  };
  const {
    site_markup,
    organization_markup,
    logo_markup,
    product_markup,
  } = prismicSiteHead!.data!;

  if (site_markup) {
    try {
      const markup = JSON.parse(site_markup.text!);
      schemaMarkup['@graph'].push(markup as WebSite);
    } catch (error) {
      // Do nothing
    }
  }
  
  if (organization_markup) {
    try {
      const markup = JSON.parse(organization_markup.text!);
      schemaMarkup['@graph'].push(markup as Organization);
    } catch (error) {
      // Do nothing
    }
  }

  if (logo_markup) {
    try {
      const markup = JSON.parse(logo_markup.text!);
      schemaMarkup['@graph'].push(getImageObjectMarkup(markup));
    } catch (error) {
      // Do nothing
    }
  }

  if (product_markup) {
    try {
      const markup = JSON.parse(product_markup.text!);
      schemaMarkup['@graph'].push(markup as Product);
    } catch (error) {
      // Do nothing
    }
  }

  const pageMarkup: WebPageMarkupType = {
    url: data.url,
    name: data.title,
    description: data.metadescription,
    thumbnailUrl: data.socialMediaImage,
  };

  if (props.breadcrumbMarkup) {
    const breadcrumbSchema = getBreadcrumbListMarkup(props.breadcrumbMarkup);

    schemaMarkup["@graph"].push(breadcrumbSchema);

    pageMarkup.breadcrumb = {
      "@type": "BreadcrumbList",
      "@id": breadcrumbSchema["@id"],
    };
  }

  if (props.imageObjectMarkup) {
    const imageObjectMarkups = (Array.isArray(props.imageObjectMarkup) ? props.imageObjectMarkup : [props.imageObjectMarkup])
      .map((markup) => getImageObjectMarkup(markup));
    
    schemaMarkup["@graph"].push(...imageObjectMarkups);

    pageMarkup.primaryImageOfPage = {
      '@id': imageObjectMarkups[0]?.['@id'] as string,
    };
  }

  schemaMarkup["@graph"].push(getWebPageMarkup({ ...pageMarkup, ...(props.webPageMarkup || {}) })); 

  return (
    <Helmet htmlAttributes={{ lang: data.lang }} defer={false}>
      <meta charSet="utf-8" />
      <title>{`${data.title} | Shine`}</title>
      <meta name="description" content={data.metadescription} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
      />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta key="og:type" property="og:type" content="website" />
      <meta
        key="og:title"
        property="og:title"
        content={data.socialMediaTitle}
      />
      <meta name="twitter:title" content={data.socialMediaTitle} />
      <meta
        key="og:description"
        property="og:description"
        content={data.metadescription}
      />
      <meta name="twitter:description" content={data.metadescription} />
      {data.url && (
        <meta
          key="og:url"
          property="og:url"
          content={`${process.env.GATSBY_HOST}${data.url}`}
        />
      )}
      {data.url && (
        <link rel="canonical" href={`${process.env.GATSBY_HOST}${data.url}`} />
      )}
      {!data.indexed && <meta name="robots" content="noindex, nofollow" />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        key="og:image"
        property="og:image"
        content={data.socialMediaImage}
      />
      <meta
        key="og:image:url"
        property="og:image:url"
        content={data.socialMediaImage}
      />
      <meta key="og:image:width" property="og:image:width" content="1200px" />
      <meta key="og:image:height" property="og:image:height" content="600px" />
      <meta name="twitter:image" content={data.socialMediaImage} />
      <meta name="twitter:image:width" content="1200px" />
      <meta name="twitter:image:height" content="600px" />

      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>

      {shouldAddHotjar ? <script type="text/javascript">{`
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3881565,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `}</script> : null}
    </Helmet>
  );
};

export default Meta;
