import { useEffect, useRef } from "react";

import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";

const useIframe = (embed: string) => {
  const {
    referrerCode,
    affiliateSource,
    affiliateLandingHref,
  } = useVisitorContext();
  const iframe = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const iframeWindow: any = iframe.current?.contentWindow;

    if (!iframeWindow) {
      throw new Error(
        "useIframe requires to be connected to an HTMLIFrameElement using ref"
      );
    }

    const document = iframeWindow.document as Document;

    // Get the affiliate name if affiliateSource is `/lp/[offre|partanaire]/[affSource]` and also handle new 'AFFILAE' case
    iframeWindow.affSource = affiliateSource?.split("/")[3] || affiliateSource;
    iframeWindow.affiliateLandingHref = affiliateLandingHref;
    iframeWindow.referrerCode = referrerCode;

    document.documentElement.innerHTML = "";
    document.open();
    document.write(`<html><body>${embed}</body></html>`);
    document.close();
  }, [affiliateLandingHref, affiliateSource, embed, referrerCode]);

  return iframe;
};

export default useIframe;
