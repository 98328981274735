import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { Helmet } from "react-helmet";

const PageScriptStorylane: FC<{ storylaneUrl: string }> = ({ storylaneUrl }) => {
  return (
    <Box>
      <Helmet>
        <script src="https://js.storylane.io/js/v1/storylane.js" />
      </Helmet>
      <Box
        className='sl-embed'
        sx={{
          position: "relative",
          paddingBottom: "56.25%",
          width: "100%",
          height: "0",
          transform: "scale(1)"
        }}
      >
        <iframe
          allow="fullscreen; camera; microphone"
          className="sl-demo"
          name="sl-embed"
          src={storylaneUrl}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            border: "none"
          }}
        />
      </Box>
    </Box>
  );
};

export default PageScriptStorylane;
