import { BreadcrumbListMarkupType } from "./types";

export const breadcrumbMarkup = (
  page: { name: string, path: string },
): BreadcrumbListMarkupType => {
  const { name, path } = page;

  return {
    itemListElement: [
      { name: 'Shine', url: `${process.env.GATSBY_HOST}/` },
      { "@type": "ReadAction", name, url: `${process.env.GATSBY_HOST}${path}` },
    ]
  }
}
