import { graphql, PageProps } from "gatsby";
import { FC, Fragment } from "react";
import Meta from "src/components/Meta";
import { linkResolver } from "src/lib/linkResolver";
import PageScriptIframe from "./views/PageScriptIframe";
import PageScriptTypeform from "./views/PageScriptTypeform";
import PageScriptStorylane from "./views/PageScriptStorylane";
import { sanitizeStorylaneUrl } from "./utils";
import { breadcrumbMarkup } from "src/components/Meta/Markups/utils";
import { ImageObjectMarkupType } from "src/components/Meta/Markups/imageObjectMarkup";

const PrismicPageScript: FC<PageProps<Gatsby.PrismicPageScriptQuery>> = ({
  data: { prismicPageScript },
}) => {
  if (!prismicPageScript?.data) throw Error();

  const {
    indexed,
    social_media_thumbnail,
    social_media_title,
    meta_description,
    meta_title,
    script_file,
    typeform_url,
    storylane_url
  } = prismicPageScript.data;

  const safeStorylaneUrl = sanitizeStorylaneUrl(storylane_url);

  const path = linkResolver(prismicPageScript);

  let thumbnailUrl: string | undefined;
  let imageObjectMarkup: ImageObjectMarkupType | undefined;

  if (social_media_thumbnail?.document?.__typename === "PrismicElementImage" && social_media_thumbnail.document.data?.image_file) {
    const { url, dimensions, alt } = social_media_thumbnail.document.data.image_file;
    thumbnailUrl = url;
    imageObjectMarkup = {
      '@id': `${process.env.GATSBY_HOST}/#/schema/page/image/`,
      url,
      contentUrl: url,
      width: String(dimensions?.width),
      height: String(dimensions?.height),
      caption: alt,
    }
  }

  return (
    <Fragment>
      <Meta
        url={path}
        indexed={indexed}
        title={meta_title}
        metadescription={meta_description}
        socialMediaTitle={social_media_title}
        socialMediaImage={thumbnailUrl}
        {...(path !== '/' ? { breadcrumbMarkup: breadcrumbMarkup({ name: meta_title!, path }) } : {})}
        {...(imageObjectMarkup ? { imageObjectMarkup } : {})}
        webPageMarkup={{
          name: meta_title,
          description: meta_description,
          thumbnailUrl,
          datePublished: prismicPageScript.first_publication_date,
          dateModified: prismicPageScript.last_publication_date,
        }}
      />
      {safeStorylaneUrl ? <PageScriptStorylane storylaneUrl={safeStorylaneUrl} /> : null}

      {!storylane_url && script_file?.localFile?.text && (
        <PageScriptIframe script={script_file.localFile.text} />
      )}

      {typeform_url && <PageScriptTypeform typeformUrl={typeform_url} />}
    </Fragment>
  );
};

export default PrismicPageScript;

export const query = graphql`
  query PrismicPageScript($uid: String) {
    prismicPageScript(uid: { eq: $uid }) {
      type
      uid
      first_publication_date
      last_publication_date

      data {
        indexed
        meta_description
        meta_title
        social_media_thumbnail {
          document {
            __typename
            ...ElementImageUrl
          }
        }
        social_media_title

        script_file {
          localFile {
            text
          }
        }
        storylane_url
        typeform_url
      }
    }
  }
`;
