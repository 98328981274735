import { ImageObject } from 'schema-dts';

export type ImageObjectMarkupType = Partial<ImageObject>;

const getImageObjectMarkup = ({
  '@id': id,
  url,
  contentUrl,
  width,
  height,
  caption,
  ...rest
}: ImageObjectMarkupType): ImageObject => {
  return {
    "@type": "ImageObject",
    "@id": id,
    inLanguage: "fr-FR",
    url,
    contentUrl,
    width,
    height,
    caption,
    ...rest,
  };
};

export default getImageObjectMarkup;
