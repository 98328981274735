import { BreadcrumbList, ListItem, ReadAction } from 'schema-dts';

export type BreadcrumbListMarkupType = Partial<Omit<BreadcrumbList,
  | 'itemListElement'
  > & {
    itemListElement: Array<Partial<ListItem | ReadAction> & { url: string }>
  }>;

const getBreadcrumbListMarkup = ({
  url,
  itemListElement,
  ...rest
}: BreadcrumbListMarkupType): BreadcrumbList => {
  const href = typeof window !== 'undefined' ? window.location.href : '';

  return {
    "@type": "BreadcrumbList",
    "@id": `${(url || href)}#breadcrumb`,
    itemListElement: itemListElement!.map((item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: item.name,
      item: {
        "@type": item['@type'] || "Thing",
        "@id": item.url,
      }
    })),
    ...rest,
  };
};

export default getBreadcrumbListMarkup;
